import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { Nav } from '../../components';

import Home from './home/Home';
import Profile from './profile/Profile';
import Session from './session/Session';
import Library from './library/Library';

import './Dashboard.scss';

function Dashboard() {
  const { path } = useRouteMatch();

  return (
    <section className='container-wrap dashboard'>
      <section className='container'>
        <Switch>
          <Route path={`${path}/home`} component={Home} />
          <Route path={`${path}/profile`} component={Profile} />
          <Route path={`${path}/session`} component={Session} />
          <Route path={`${path}/library`} component={Library} />
          <Route path={`${path}/help`}>help</Route>

          <Route path='*'>
            <Redirect to={`${path}/home`} />
          </Route>
        </Switch>
      </section>

      <Nav />
    </section>
  );
}

export default Dashboard;

import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { SubmittableInput, DataLink } from 'components';

import ProfileEditMainImg from './profile-edit-main-img/ProfileEditMainImg';

import {
  changeProfileName,
  getImg,
  getProfile,
  // updateProfile,
  // uploadProfileImage,
} from '../../Profile-api';

import { logout } from 'core';

function ProfileEditMain() {
  const { path } = useRouteMatch();

  const [profile, setProfile] = useState(null);
  const [img, setImg] = useState('');

  const dataLinks = [
    {
      label: 'Сменить еmail',
      data: profile?.email,
      href: `${path}/email`,
    },
    {
      label: 'Сменить номер',
      data: profile?.mobileNumber,
      href: `${path}/phone`,
    },
    {
      label: 'Сменить пароль',
      href: `${path}/password`,
    },
    {
      label: 'Сменить код для входа',
      href: `${path}/pin`,
    },
  ];

  const handleNameUpdate = (value) => {
    changeProfileName(value);
  };

  useEffect(() => {
    getImg().then((data) => {
      setImg(data.img);
    });

    getProfile().then((data) => {
      const { name, email, mobileNumber } = data;

      setProfile({
        ...profile,
        name,
        email,
        mobileNumber,
      });
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='profile-edit__header'>
        <Link to='main'>
          <small className='profile-edit__back'>Отмена</small>
        </Link>
        <h1>Редактирование профиля</h1>
      </div>

      <ProfileEditMainImg img={img} />

      <SubmittableInput
        name='name'
        label='Имя и Фамилия'
        value={profile?.name || ''}
        onSubmit={handleNameUpdate}
      />

      {dataLinks.map((dataLink, index) => {
        return <DataLink {...dataLink} key={index} />;
      })}

      <button
        onClick={() => {
          logout();
        }}
        className='profile-edit__logout'
      >
        <small className='color-red'>Выйти из аккаунта</small>
      </button>
    </>
  );
}

export default ProfileEditMain;

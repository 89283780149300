function TextInput({
  field,
  label = '',
  placeholder = '',
  error,
  isInvalid,
  isValid,
  helper,
}) {
  return (
    <div className='form-group'>
      <label className='form-label'>{label}</label>

      <div className='input-group'>
        <input
          {...field}  
          className={`form-input ${isInvalid ? 'is-invalid' : ''}`}
          placeholder={
            placeholder ? placeholder : `Введите ${label.toLowerCase()}`
          }
          type='text'
        />
        {isValid ? (
          <div className='form-suffix'>
            <img src='/assets/icons/input-valid.svg' alt='Введено корректно' />
          </div>
        ) : (
          ''
        )}
      </div>

      {isInvalid && error ? (
        <div className='form-helper'>
          <small className='color-red'>{error}</small>
        </div>
      ) : (
        ''
      )}

      {helper ? (
        <div className='form-helper'>
          <small className='color-light-dark'>{helper}</small>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default TextInput;

import { useHistory } from 'react-router-dom';
import './BackLink.scss';

function BackLink() {
  const history = useHistory();

  return (
    <button
      onClick={() => {
        history.goBack();
      }}
      className='backlink'
    >
      <img src='/assets/icons/back-arrow.svg' alt='Назад' />
    </button>
  );
}

export default BackLink;

import {
  BackLink,
  // Tabs
} from 'components';

// import { RECOVERY_TABS } from './RecoveryWrapper-data';

function RecoveryWrapper({
  children,
  // withTabs = true
}) {
  // const tabs = RECOVERY_TABS;

  return (
    <section className='recovery container-wrap container-wrap--justify-center'>
      <section className='container'>
        <section className='recovery'>
          <div className='recovery__header'>
            <BackLink />
            <h1>Восстановление пароля</h1>
            {/* {withTabs ? <Tabs tabs={tabs} /> : ''} */}
          </div>
          <div className='recovery__body'>{children}</div>
        </section>
      </section>
    </section>
  );
}

export default RecoveryWrapper;

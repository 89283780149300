import { useState } from 'react';
import { Button, ProfileImg } from 'components';

import { deleteProfileImage, uploadProfileImage } from '../../../Profile-api';

import './ProfileEditMainImg.scss';

function ProfileEditMainImg({ img }) {
  const [isBackdropShown, setIsBackdropShown] = useState(false);

  const imageDeleteHandler = () => {
    deleteProfileImage();
  };

  const imageChangeHandler = (event) => {
    if (event.target.files.length > 0) {
      uploadProfileImage(event.target.files[0]);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          setIsBackdropShown(true);
        }}
        className='profile-edit-main-img'
      >
        <ProfileImg img={img} isCentered={true} />
        <span className='profile-edit-main-img__icon'>
          <img src='/assets/icons/edit.svg' alt='Загрузить' />
        </span>
      </button>

      {isBackdropShown ? (
        <div
          className='backdrop'
          onClick={() => {
            setIsBackdropShown(false);
          }}
        >
          <ul
            onClick={(event) => {
              event.stopPropagation();
            }}
            className='backdrop-menu'
          >
            <li className='backdrop-menu__item'>
              <Button onClick={imageDeleteHandler} color='link-red'>
                Удалить
              </Button>
            </li>
            <li className='backdrop-menu__item'>
              <input
                type='file'
                id='avatarUpload'
                onChange={imageChangeHandler}
              />
              <label className='btn btn--link' htmlFor='avatarUpload'>
                Выбрать фото
              </label>
            </li>
            <li className='backdrop-menu__item backdrop-menu__item--cancel'>
              <Button
                onClick={() => {
                  setIsBackdropShown(false);
                }}
                color='blue'
              >
                Отменить
              </Button>
            </li>
          </ul>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default ProfileEditMainImg;

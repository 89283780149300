import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import ProfileEdit from './profile-edit/ProfileEdit';
import ProfileMain from './profile-main/ProfileMain';

function Profile() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/main`} component={ProfileMain} />
      <Route path={`${path}/edit`} component={ProfileEdit} />
      <Route path='*'>
        <Redirect to={`${path}/main`} />
      </Route>
    </Switch>
  );
}

export default Profile;

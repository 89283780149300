import * as Yup from 'yup';

import { hasNonPhoneCharacters, isEmailValid } from 'core';
import {
  // PASSWORD_MIN_LENGTH, 
  USERNAME_MAX_LENGTH
} from 'config';

export const LOGIN_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required('Поле обязательно к заполнению')
    .max(
      USERNAME_MAX_LENGTH,
      `Превышена максимально допустимое кол-во символов (${USERNAME_MAX_LENGTH})`
    )
    .test('username', 'Email введен некорректно', function (value) {
      return !(hasNonPhoneCharacters(value) && !isEmailValid(value));
    }),
  password: Yup.string()
    .required('Поле обязательно к заполнению')
  // .min(
  //   PASSWORD_MIN_LENGTH,
  //   `Минимальная длина пароля: ${PASSWORD_MIN_LENGTH} символов`
  // ),
});

export const LOGIN_INITIAL_VALUES = {
  username: '',
  password: '',
}
import { Link } from 'react-router-dom';

import { Button, LogoSmall } from 'components';

import './Home.scss';
import { useEffect, useState } from 'react';
import { getSessions } from '../profile/Profile-api';
import HomeSessions from './home-sessions/HomeSessions';

function Home() {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    getSessions().then((data) => {
      setSessions(data);
    });
  }, []);

  return (
    <section className='home'>
      <LogoSmall />

      <article className='home__hero'>
        <h1>Главная</h1>

        {sessions.length > 0 ? (
          <HomeSessions />
        ) : (
          <small className='color-light-dark'>
            У вас нет задач, вы можете создать сессию
          </small>
        )}
      </article>

      <div className='home__buttons'>
        <Link to='/dashboard/session' className='btn btn--blue'>
          {sessions.length > 0 ? 'Создать новую сессию' : 'Создать сессию'}
        </Link>
        <Button color='light-blue' type='button'>
          Библиотека
        </Button>
      </div>
    </section>
  );
}

export default Home;

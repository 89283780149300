export const NavIcons = {
    home: `
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C12.7168 1.84527e-05 12.4449 0.110906 12.2425 0.308919L0.425293 9.97428C0.411644 9.98455 0.39824 9.99513 0.385091 10.006L0.344889 10.0399V10.042C0.236235 10.1432 0.14955 10.2657 0.0902204 10.4018C0.0308902 10.538 0.000180997 10.6848 0 10.8333C0 11.1207 0.114137 11.3962 0.317301 11.5994C0.520465 11.8025 0.796016 11.9167 1.08333 11.9167H2.16667V23.8333C2.16667 25.0304 3.13625 26 4.33333 26H21.6667C22.8638 26 23.8333 25.0304 23.8333 23.8333V11.9167H24.9167C25.204 11.9167 25.4795 11.8025 25.6827 11.5994C25.8859 11.3962 26 11.1207 26 10.8333C26.0001 10.6845 25.9695 10.5372 25.9102 10.4007C25.8509 10.2642 25.764 10.1413 25.6551 10.0399L25.6382 10.0272C25.6144 10.0056 25.5897 9.98517 25.5641 9.96582L23.8333 8.55029V4.33333C23.8333 3.73533 23.348 3.25 22.75 3.25H21.6667C21.0687 3.25 20.5833 3.73533 20.5833 4.33333V5.89274L13.7342 0.289876C13.5346 0.104353 13.2725 0.000850779 13 0ZM16.25 14.0833H20.5833V22.75H16.25V14.0833Z" fill="currentColor" />
        </svg>
    `,
    profile: `
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.25 17.333V15.1662C16.7375 14.9246 18.1318 13.2649 18.278 11.9681C18.6615 11.9388 19.2638 11.5867 19.4415 10.1968C19.5368 9.45031 19.1577 9.03104 18.928 8.89887C18.928 8.89887 19.5 7.81224 19.5 6.50027C19.5 3.86983 18.4676 1.62507 16.25 1.62507C16.25 1.62507 15.4798 0 13 0C8.4045 0 6.5 2.94787 6.5 6.50027C6.5 7.69632 7.072 8.89887 7.072 8.89887C6.84233 9.03104 6.46317 9.45139 6.5585 10.1968C6.73617 11.5867 7.3385 11.9388 7.722 11.9681C7.86825 13.2649 9.2625 14.9246 9.75 15.1662V17.333C8.66667 20.5831 0 18.4164 0 26H26C26 18.4164 17.3333 20.5831 16.25 17.333Z" fill="currentColor"/>
        </svg>
    `,
    help: `
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 14C28 6.28025 21.7197 0 14 0C6.28025 0 0 6.28025 0 14C0 16.5159 0.669677 18.9655 1.94134 21.1161L0.0514706 27.9482L6.88391 26.0587C9.03449 27.3303 11.4841 28 14 28C21.7197 28 28 21.7197 28 14ZM11.8517 10.7417H9.70333C9.70333 8.37236 11.6307 6.44501 14 6.44501C16.3693 6.44501 18.2967 8.37236 18.2967 10.7417C18.2967 11.9443 17.7876 13.1001 16.8994 13.9124L15.0742 15.583V17.2583H12.9258V14.6369L15.449 12.3275C15.8999 11.9149 16.1483 11.3518 16.1483 10.7417C16.1483 9.55703 15.1847 8.59335 14 8.59335C12.8153 8.59335 11.8517 9.55703 11.8517 10.7417ZM12.9258 19.4067H15.0742V21.555H12.9258V19.4067Z" fill="currentColor"/>
        </svg>
    `,
    library: `
        <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.46155 0.5C1.10155 0.5 1.52588e-05 1.51705 1.52588e-05 2.77273V20.9545C1.52588e-05 22.2102 1.10155 23.2273 2.46155 23.2273H12.3077C13.6677 23.2273 14.7692 24.2443 14.7692 25.5V2.77273C14.7692 1.51705 13.6677 0.5 12.3077 0.5H2.46155ZM19.6923 0.5C18.3323 0.5 17.2308 1.51705 17.2308 2.77273V25.5C17.2308 24.2443 18.3323 23.2273 19.6923 23.2273H29.5385C30.8985 23.2273 32 22.2102 32 20.9545V2.77273C32 1.51705 30.8985 0.5 29.5385 0.5H19.6923Z" fill="currentColor"/>
        </svg>
    `,
    newSession: `
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0.5C6.54463 0.5 0.5 6.54463 0.5 14C0.5 21.4554 6.54463 27.5 14 27.5C21.4554 27.5 27.5 21.4554 27.5 14C27.5 6.54463 21.4554 0.5 14 0.5ZM20.75 15.125H15.125V20.75C15.125 21.3721 14.621 21.875 14 21.875C13.379 21.875 12.875 21.3721 12.875 20.75V15.125H7.25C6.629 15.125 6.125 14.6221 6.125 14C6.125 13.3779 6.629 12.875 7.25 12.875H12.875V7.25C12.875 6.62787 13.379 6.125 14 6.125C14.621 6.125 15.125 6.62787 15.125 7.25V12.875H20.75C21.371 12.875 21.875 13.3779 21.875 14C21.875 14.6221 21.371 15.125 20.75 15.125Z" fill="currentColor"/>
        </svg>
    `
    
}
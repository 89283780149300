import { Link } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';

import { LogoSmall, Button, TextInput } from 'components';

import { FORM_INITIAL_VALUES, FORM_SCHEMA } from './RegisterForm-data';
import './RegisterForm.scss';

function RegisterForm({ onFormSubmit }) {
  const validationSchema = FORM_SCHEMA;
  const initialValues = FORM_INITIAL_VALUES;

  const onSubmitHandler = (values) => {
    onFormSubmit(values);
  };

  return (
    <section className='container-wrap container-wrap--justify-center'>
      <section className='register-form container'>
        <LogoSmall />

        <h1>Регистрация</h1>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
        >
          {({ errors, touched }) => (
            <Form className='register-form__body'>
              <Field
                name='name'
                label='Имя'
                isValid={!errors.name && touched.name}
                isInvalid={errors.name && touched.name}
                error={errors.name}
                component={TextInput}
              />

              <Field
                name='surname'
                label='Фамилия'
                isValid={!errors.surname && touched.surname}
                isInvalid={errors.surname && touched.surname}
                error={errors.surname}
                component={TextInput}
              />

              <Field
                name='email'
                label='Email'
                isValid={!errors.email && touched.email}
                isInvalid={errors.email && touched.email}
                error={errors.email}
                component={TextInput}
              />

              <Field
                name='phone'
                label='Телефон'
                isValid={!errors.phone && touched.phone}
                isInvalid={errors.phone && touched.phone}
                error={errors.phone}
                component={TextInput}
              />

              <small className='register-form__agreement color-light-dark'>
                Нажимая продолжить вы соглашаетесь с{' '}
                <Link className='link-blue' to='/auth/rules'>
                  Политикой Приватности
                </Link>{' '}
                и{' '}
                <Link className='link-blue' to='/auth/rules'>
                  Правилами Сервиса
                </Link>
              </small>

              <div className='auth__footer'>
                <Button type='submit'>Продолжить</Button>

                <div className='btn btn-link register-form__login'>
                  <span className='color-light-dark'>Уже есть аккаунт?</span>
                  <Link to='/auth/login'>Войти</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </section>
  );
}

export default RegisterForm;

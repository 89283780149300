import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';
import { Pin } from 'components';

function ProfileEditPin({ onSubmit }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const currentPin = localStorage.getItem('pin');

  const [newPin, setNewPin] = useState(null);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        {currentPin ? (
          <Pin
            isBiometryShown={true}
            validatePin={(pin) => {
              return pin === currentPin;
            }}
            onEnter={(pin) => {
              history.push(`${path}/new`);
            }}
            title='Введите код для входа'
          />
        ) : (
          <Redirect to={`${path}/new`} />
        )}
      </Route>

      <Route path={`${path}/new`}>
        <Pin
          isBiometryShown={false}
          onEnter={(pin) => {
            setNewPin(pin);
            history.push(`${path}/confirm`);
          }}
          title='Создайте код для входа'
        />
      </Route>

      <Route path={`${path}/confirm`}>
        <Pin
          isBiometryShown={true}
          validatePin={(pin) => {
            return pin === newPin;
          }}
          onEnter={(pin) => {
            onSubmit(pin);
          }}
          title='Повторите код'
        />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default ProfileEditPin;

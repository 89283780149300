import { Field, Form, Formik } from 'formik';

import { Button, RecoveryWrapper, TextInput } from 'components';

import { PHONE_VALUES, PHONE_SCHEMA } from './RecoveryPhone-data';

function RecoveryPhone({ onSubmit }) {
  const validationSchema = PHONE_SCHEMA;
  const initialValues = PHONE_VALUES;

  return (
    <RecoveryWrapper>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              name='phone'
              label='Номер телефона'
              isValid={!errors.phone && touched.phone}
              isInvalid={errors.phone && touched.phone}
              error={errors.phone}
              helper='Введите ваш номер чтобы сбросить старый пароль'
              component={TextInput}
            />

            <div className='auth__footer'>
              <Button type='submit'>Продолжить</Button>
            </div>
          </Form>
        )}
      </Formik>
    </RecoveryWrapper>
  );
}

export default RecoveryPhone;

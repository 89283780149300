import { Link } from 'react-router-dom';

import './DataLink.scss';

function DataLink({ label, data = '', href }) {
  return (
    <Link
      className='data-link'
      to={{
        pathname: href,
        state: data,
      }}
    >
      <small className='color-dark data-link__label'>{label}</small>
      <span className='text-tiny color-light-gray'>{data}</span>
      <img
        className='data-link__icon'
        src='/assets/icons/data-arrow.svg'
        alt={label}
      />
    </Link>
  );
}

export default DataLink;

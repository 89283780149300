import { SetPassword } from 'components';

import { PASSWORD_INITIAL_VALUES } from './RegisterPassword-data';

function RegisterPassword({ onPasswordSubmit }) {
  const initialValues = PASSWORD_INITIAL_VALUES;

  return (
    <SetPassword initialValues={initialValues} onSubmit={onPasswordSubmit} />
  );
}

export default RegisterPassword;

import { BackLink, Select } from 'components';

import { SESSION_TYPES } from './SessionType-data';

function SessionType({ onSelect }) {
  const selectHandler = (selected) => {
    onSelect(selected.value);
  };

  return (
    <section className='session'>
      <div className='page__header'>
        <BackLink />
        <h1>Выбор типа работы</h1>
      </div>
      <div className='session__body'>
        <Select options={SESSION_TYPES} onSubmit={selectHandler} />
      </div>
    </section>
  );
}

export default SessionType;

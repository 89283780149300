import { Field, Form, Formik } from 'formik';
import { Button, BarInput } from 'components';

function HomeSessions() {
  const initialValues = {
    search: '',
  };
  const submitHandler = (values) => {};

  return (
    <div className='home-sessions'>
      <small className='home-sessions__text color-light-dark'>
        У вас есть незавершенные сессии
      </small>
      <small className='home-sessions__text color-light-dark'>
        Вы так же можете создать новую сессию
      </small>

      <Formik initialValues={initialValues} onSubmit={submitHandler}>
        {({ errors }) => (
          <Form>
            <Field
              name='search'
              placeholder='Поиск по IMEI, SIM или VIN-номеру'
              error={errors.search}
              component={BarInput}
            />

            <Button color='gray-small' type='submit'>
              Сохранить информацию
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HomeSessions;

import { api } from 'core';

export const getImg = () => {
    const url = '/profile/image';
    return api.get(url);
}

export const getProfile = () => {
    const url = '/profile';
    return api.get(url);
}

export const updateProfile = (values) => {
    const url = `/profile`;
    return api.put(url, values)
}

export const uploadProfileImage = (file) => {
    const url = '/profile/image';

    const formData = new FormData();
    formData.append("file", file);

    return api.post(url, formData);
}

export const deleteProfileImage = () => {
    const url = '/profile/image';
    return api.delete(url);
}

export const getSessions = () => {
    const url = '/masters/sessions';
    return api.get(url);
}

export const changeProfileName = (name) => {
    console.log(name);
}
import { Slide } from "react-toastify";

export const USERNAME_MAX_LENGTH = 30;
export const PASSWORD_MIN_LENGTH = 8;

// export const API_BASE_URL = 'https://api.mobiliuz.com';
export const API_BASE_URL = 'https://api.sber.mobiliuz.com';

export const TOAST_CONFIG = {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    transition: Slide
}
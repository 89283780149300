import { useEffect, useState } from 'react';
import {
  Link,
  // Route, Switch,
  // useRouteMatch,
} from 'react-router-dom';

import {
  ProfileImg,
  // Tabs
} from 'components';

// import ProfileHistory from './profile-history/ProfileHistory';
// import ProfileStatistics from './profile-statistics/ProfileStatistics';

// import { PROFILE_TABS } from './ProfileMain-data';

import { getImg, getProfile } from '../Profile-api';

import './ProfileMain.scss';

function ProfileMain() {
  // const { path } = useRouteMatch();
  const [data, setData] = useState(null);
  const [img, setImg] = useState('');

  useEffect(() => {
    getImg().then((data) => {
      setImg(data.img);
    });

    getProfile().then((data) => {
      setData([
        {
          label: 'Имя и фамилия',
          value: data.name,
        },
        {
          label: 'Email',
          value: data.email,
        },
        {
          label: 'Телефон',
          value: data.mobileNumber,
        },
      ]);
    });
  }, []);

  return (
    <section className='profile-main'>
      <div className='profile-main__header'>
        <h1>Профиль</h1>
        <Link to='/dashboard/profile/edit'>
          <img src='/assets/icons/edit.svg' alt='Редактирование профиля' />
        </Link>
      </div>

      <ProfileImg img={img} />

      {data ? (
        <div className='profile-main__data'>
          {data.map(({ label, value }, index) => {
            return (
              <div key={index} className='form-group'>
                <label className='form-label'>{label}</label>
                <div className='input-group'>
                  <div className='form-control'>{value}</div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}

      {/* <Tabs tabs={PROFILE_TABS} />

      <Switch>
        <Route exact path={`${path}`} component={ProfileHistory} />
        <Route path={`${path}/statistics`} component={ProfileStatistics} />
        <Route path={`${path}/filter`} component={ProfileStatistics} />
      </Switch> */}
    </section>
  );
}

export default ProfileMain;

import React from 'react';

import { NavLink } from 'react-router-dom';
import InlineSVG from 'svg-inline-react';

import { NavIcons } from './Nav-icons';
import './Nav.scss';

function Nav() {
  const nav = [
    {
      icon: NavIcons.home,
      href: '/dashboard/home',
      title: 'Домой',
    },
    {
      icon: NavIcons.profile,
      href: '/dashboard/profile',
      title: 'Профиль',
    },
    {
      icon: NavIcons.newSession,
      href: '/dashboard/session',
      title: 'Новая сессия',
    },
    {
      icon: NavIcons.library,
      href: '/dashboard/library',
      title: 'Библиотека',
    },
    {
      icon: NavIcons.help,
      href: '/dashboard/help',
      title: 'Поддержка',
    },
  ];
  return (
    <nav className='nav'>
      {nav.map((item, index) => {
        return (
          <NavLink
            key={index}
            to={item.href}
            activeClassName='nav__item--active'
            className='nav__item'
          >
            <InlineSVG src={item.icon} />
            <div className='nav__title'>{item.title}</div>
          </NavLink>
        );
      })}
    </nav>
  );
}

export default Nav;

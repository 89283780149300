import { useState } from 'react';
import { Link } from 'react-router-dom';

function PasswordInput({
  field,
  label = 'Пароль',
  placeholder = '',
  error,
  isInvalid,
  isValid,
  hasRecoveryLink = true,
}) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <div className='form-group'>
      <label className='form-label'>{label}</label>
      <div className='input-group'>
        <input
          {...field}
          className={`form-input ${isInvalid ? 'is-invalid' : ''}`}
          placeholder={
            placeholder ? placeholder : `Введите ${label.toLowerCase()}`
          }
          type={isPasswordShown ? 'text' : 'password'}
        />
        {/* {isValid ? (
          <div className='form-suffix'>
            <img src='/assets/icons/input-valid.svg' alt='Введено корректно' />
          </div>
        ) : ( */}
        <button
          className='form-suffix'
          onClick={(event) => {
            event.preventDefault();
            setIsPasswordShown(!isPasswordShown);
          }}
        >
          <img
            src={
              isPasswordShown
                ? '/assets/icons/eye-closed.svg'
                : '/assets/icons/eye.svg'
            }
            alt={isPasswordShown ? 'Скрыть пароль' : 'Показать пароль'}
          />
        </button>
      </div>

      {isInvalid && error ? (
        <div className='form-helper'>
          <small className='color-red'>{error}</small>
        </div>
      ) : (
        ''
      )}

      {hasRecoveryLink ? (
        <Link className='form-helper' to='/auth/recovery'>
          <small className='color-light-blue'>Забыли пароль?</small>
        </Link>
      ) : (
        ''
      )}
    </div>
  );
}

export default PasswordInput;

import { Sms } from 'components';

function ProfileEditPhoneSms({ phone, onSubmit }) {
  const validateCode = (code) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
    // return verifySms({ ...values, code });
  };

  const requestCode = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
    // return sendSms(values);
  };

  const handleEnter = (values) => {
    onSubmit(values);
  };

  return (
    <section className='profile-edit'>
      <section className='container'>
        <Sms
          onEnter={handleEnter}
          requestCode={requestCode}
          validateCode={validateCode}
        />
      </section>
    </section>
  );
}

export default ProfileEditPhoneSms;

import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import { hasNonPhoneCharacters } from 'core';
import { TextInput, PasswordInput, Button, Logo } from 'components';

import './LoginForm.scss';

import { LOGIN_INITIAL_VALUES, LOGIN_SCHEMA } from './LoginForm-data';
import { login } from './LoginForm-api';

function LoginForm({ onLogin }) {
  const validationSchema = LOGIN_SCHEMA;
  const initialValues = LOGIN_INITIAL_VALUES;

  const [error, setError] = useState('');

  const submitHandler = async ({ username, password }) => {
    setError('');

    const loginData = {
      [hasNonPhoneCharacters(username) ? 'username' : 'phone']: username,
      password: password,
    };

    try {
      const token = await login(loginData);
      onLogin(token);
    } catch (error) {
      console.log(error);
      setError('Неверные данные, попробуйте еще раз');
    }
  };

  return (
    <section className='container-wrap container-wrap--justify-center'>
      <section className='login-form container'>
        <Logo />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submitHandler}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name='username'
                label='Email или телефон'
                isValid={!errors.username && !error && touched.username}
                isInvalid={(errors.username && touched.username) || error}
                error={errors.username}
                component={TextInput}
              />
              <Field
                name='password'
                isValid={!errors.password && !error && touched.password}
                isInvalid={(errors.password && touched.password) || error}
                error={errors.password || error}
                component={PasswordInput}
              />

              <div className='auth__footer'>
                <Button type='submit'>Войти</Button>

                <Link className='btn btn-default' to='/auth/register'>
                  Регистрация
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </section>
  );
}

export default LoginForm;

import { api } from 'core';

export const recoveryByPhone = async (body) => {
    const url = '/recovery/password/mobile/request';
    return api.post(url, body);
}

export const recoveryByEmail = async (body) => {
    const url = '/recovery/password/request';
    return api.post(url, body);
}

export const sendSms = async (body) => {
    const url = '/sign-up/send-otp';
    return api.post(url, body);
}

export const verifySms = async (body) => {
    const url = '/recovery/password/mobile/verify';
    return api.post(url, body);
}

export const setPassword = async (body)=>{
    const url = '/recovery/password/set';
    return api.post(url, body);
}
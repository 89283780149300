import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import { BackLink, Button, PasswordInput } from 'components';

function ProfileEditPassword({ onSubmit }) {
  const initialValues = {
    old: '',
    password: '',
    confirm: '',
  };

  const validationSchema = Yup.object().shape({
    old: Yup.string().required('Поле обязательно к заполнению'),
    password: Yup.string().required('Поле обязательно к заполнению'),
    confirm: Yup.string()
      .required('Поле обязательно к заполнению')
      .oneOf(
        [Yup.ref('password'), null],
        'Не совпадают пароли, попробуйте еще раз'
      ),
  });

  const submitHandler = (values) => {
    onSubmit(values)
  };

  return (
    <>
      <div className='page__header'>
        <BackLink />
        <h1>Изменить пароль</h1>
      </div>

      <div className='profile-edit__form'>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submitHandler}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <Field
                name='old'
                label='Введите старый пароль'
                placeholder={' '}
                isValid={!errors.old && touched.old}
                isInvalid={errors.old && touched.old}
                error={errors.old}
                hasRecoveryLink={false}
                component={PasswordInput}
              />
              <Field
                name='password'
                label='Придумайте новый пароль'
                placeholder={' '}
                isValid={!errors.password && touched.password}
                isInvalid={errors.password && touched.password}
                error={errors.password}
                hasRecoveryLink={false}
                component={PasswordInput}
              />
              <Field
                name='confirm'
                label='Повторите новый пароль'
                placeholder={' '}
                isValid={!errors.confirm && touched.confirm}
                isInvalid={errors.confirm && touched.confirm}
                error={errors.confirm}
                hasRecoveryLink={false}
                component={PasswordInput}
              />

              <div className='auth__footer'>
                <Button disabled={!(isValid && dirty)} type='submit'>
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default ProfileEditPassword;

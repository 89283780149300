import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import LoginForm from './login-form/LoginForm';
import LoginPin from './login-pin/LoginPin';

function Login({ onLogin }) {
  const { path } = useRouteMatch();
  const currentPin =
    localStorage.getItem('pin') && localStorage.getItem('authToken');

  const havePinAuthorization = !!currentPin;

  const loginHandler = (token) => {
    onLogin(token);
  };

  return (
    <Switch>
      <Route path={`${path}/form`}>
        <LoginForm onLogin={loginHandler} />
      </Route>

      <Route path={`${path}/pin`}>
        <LoginPin onLogin={loginHandler} />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}/${havePinAuthorization ? 'pin' : 'form'}`} />
      </Route>
    </Switch>
  );
}

export default Login;

function BarInput({
  field,
  placeholder = 'Поиск по IMEI, SIM или VIN-номеру',
  error,
  isInvalid,
}) {
  return (
    <div className='form-group'>
      <div className='input-group'>
        <input
          {...field}
          className={`form-input ${isInvalid ? 'is-invalid' : ''}`}
          placeholder={placeholder}
          type='text'
        />
        <button
          className='form-suffix'
          onClick={(event) => {
            event.preventDefault();
          }}
        >
          <img src='/assets/icons/barcode.svg' alt='Сканировать' />
        </button>
      </div>

      {isInvalid && error ? (
        <div className='form-helper'>
          <small className='color-red'>{error}</small>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default BarInput;

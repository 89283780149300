import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { BackLink } from 'components';

import { RULES } from './Rules-data';
import './Rules.scss';

function Rules() {
  const rules = RULES;

  return (
    <section className='container-wrap'>
      <section className='section container'>
        <section className='rules'>
          <div className='rules__header'>
            <BackLink />

            <h1>Правила</h1>
          </div>
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            {rules.map((rule, index) => {
              return (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>
                        {index + 1}. {rule.heading}
                      </h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{rule.body()}</AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </section>
      </section>
    </section>
  );
}

export default Rules;

import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';
import { toast } from 'react-toastify';

// import RecoveryEmail from './recovery-email/RecoveryEmail';
import RecoveryPassword from './recovery-password/RecoveryPassword';
import RecoveryPhone from './recovery-phone/RecoveryPhone';
import RecoverySms from './recovery-sms/RecoverySms';

import {
  // recoveryByEmail,
  recoveryByPhone,
  setPassword,
} from './Recovery-api';

function Recovery({ onRecovery }) {
  const history = useHistory();
  const [recoveryData, setRecoveryData] = useState(null);
  const { path } = useRouteMatch();

  const phoneSubmitHandler = async (values) => {
    try {
      setRecoveryData(values);
      await recoveryByPhone(values);
      history.push(`${path}/sms`);
      toast(`Ссылка сброса пароля была успешна отправлена на вашу почту`);
    } catch (error) {
      toast(`Не удалось отправить ссылку сброса на вашу почту`);
    }
  };

  // const emailSubmitHandler = async (values) => {
  //   try {
  //     setRecoveryData(values);
  //     await recoveryByEmail(values);
  //     history.push(`${path}/sms`);
  //   } catch (error) {}
  // };

  const smsSubmitHandler = async (values) => {
    try {
      setRecoveryData({ ...recoveryData, ...values });
      history.push(`${path}/password`);
    } catch (error) {}
  };

  const passwordSubmitHandler = async (values) => {
    try {
      setPassword(recoveryData);
      onRecovery(values);
    } catch (error) {}
  };

  return (
    <Switch>
      <Route path={`${path}/phone`}>
        <RecoveryPhone onSubmit={phoneSubmitHandler} />
      </Route>

      {/* <Route path={`${path}/email`}>
        <RecoveryEmail onSubmit={emailSubmitHandler} />
      </Route> */}

      <Route path={`${path}/sms`}>
        <RecoverySms onSubmit={smsSubmitHandler} values={recoveryData} />
      </Route>

      <Route path={`${path}/password`}>
        <RecoveryPassword onSubmit={passwordSubmitHandler} />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}/phone`} />
      </Route>
    </Switch>
  );
}

export default Recovery;

import { useState } from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { setApiToken } from 'core';
import { TOAST_CONFIG } from 'config';

import { Auth, Dashboard } from './pages';

import './App.scss';

function App() {
  const toastConfig = TOAST_CONFIG;
  const [isAuthorized, setIsAuthorized] = useState(false);

  const loginHandler = (token) => {
    localStorage.setItem('authToken', token);

    setApiToken(token);
    setIsAuthorized(true);
  }

  const registerHandler = () => {
    setIsAuthorized(true);
  }

  // const navigateToHome = () => {
  //   window.location.href = '/dashboard';
  // }

  // useEffect(() => {
  //   const token = localStorage.getItem('authToken');

  //   if (token) {
  //     setApiToken(token);
  //     setIsAuthorized(true);
  //   } else {
  //     setIsAuthorized(false);
  //   }
  // }, [])

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path='/auth'>
            {!isAuthorized ? <Auth onLogin={loginHandler} onRegister={registerHandler} /> : <Redirect to='/dashboard' />}
          </Route>
          <Route path='/dashboard'>
            {isAuthorized ? <Dashboard /> : <Redirect to='/auth' />}
          </Route>
          <Route path='/'>
            <Redirect to='/dashboard' />
          </Route>
        </Switch>
      </BrowserRouter>
      <ToastContainer {...toastConfig} />
    </>
  );
}

export default App;

function LogoSmall() {
  return (
    <img
      className='logo-small'
      src='/assets/images/logo-small.png'
      alt='Thea Auto'
    />
  );
}

export default LogoSmall;

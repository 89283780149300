import { Pin } from 'components';

function RegisterPin({ onPinEnter }) {
  const pinEnterHandler = (pin) => {
    onPinEnter(pin);
  };

  return (
    <section className='container-wrap'>
      <section className='container'>
        <Pin
          onEnter={pinEnterHandler}
          isBiometryShown={false}
          title='Создайте код для входа'
        />
      </section>
    </section>
  );
}

export default RegisterPin;

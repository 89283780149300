import { useEffect, useState } from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

function SubmittableInput({
  name = '',
  label = '',
  placeholder = '',
  value,
  onSubmit,
}) {
  const [savedValue, setSavedValue] = useState(value);
  const [initialValues, setInitialValues] = useState({
    [name]: value,
  });

  const validationSchema = Yup.object().shape({
    [name]: Yup.string().required('Поле обязательно к заполнению'),
  });

  const handleSubmit = (values) => {
    setSavedValue(values[name]);

    if (!onSubmit) {
      return;
    }

    onSubmit(values[name]);
  };

  const handleClear = () => {
    setInitialValues({ [name]: '' });
  };

  useEffect(() => {
    setInitialValues({
      [name]: value,
    });
    setSavedValue(value);
  }, [value, name]);

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='form-group'>
            <label className='form-label'>{label}</label>
            <Field name={name} placeholder={placeholder}>
              {({ field }) => (
                <div className='input-group'>
                  <input
                    {...field}
                    className={`form-input ${
                      errors[name] && touched[name] ? 'is-invalid' : ''
                    }`}
                    placeholder={placeholder}
                    type='text'
                  />
                  {field.value ? (
                    field.value !== savedValue ? (
                      <button
                        type='submit'
                        onClick={handleSubmit}
                        className='form-suffix'
                      >
                        <img
                          src='/assets/icons/input-submit.svg'
                          alt='Сохранить'
                        />
                      </button>
                    ) : (
                      <button
                        type='button'
                        onClick={handleClear}
                        className='form-suffix'
                      >
                        <img
                          src='/assets/icons/input-clear.svg'
                          alt='Очистить'
                        />
                      </button>
                    )
                  ) : (
                    ''
                  )}
                </div>
              )}
            </Field>

            {errors[name] && touched[name] ? (
              <div className='form-helper'>
                <small className='color-red'>{errors[name]}</small>
              </div>
            ) : (
              ''
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SubmittableInput;

import { Sms } from 'components';

import { sendSms, verifySms } from '../Recovery-api';

import './RecoverySms.scss';

function RecoverySms({ values, onSubmit }) {
  const validateCode = (code) => {
    return verifySms({ ...values, code });
  };

  const requestCode = () => {
    return sendSms(values);
  };

  const handleEnter = (values) => {
    onSubmit(values);
  };

  return (
    <section className='container-wrap'>
      <section className='container'>
        <section className='recovery'>
          <Sms
            onEnter={handleEnter}
            requestCode={requestCode}
            validateCode={validateCode}
          />
        </section>
      </section>
    </section>
  );
}

export default RecoverySms;

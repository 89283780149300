export const RULES = [
    {
        heading: 'Регистрация пользователя',
        body: () => {
            return (
                <>
                    <p>
                        Использование транспортного средства разрешено только в пределах
                        выделенной зоны (зона использования) и в соответствии с тарифным
                        планом избранное Арендатором.
                    </p>
                    <p>
                        Начало и завершение краткосрочная аренда автомобиля (проката)
                        производится в соответствии и только в местности разрешенной
                        настоящим Договором и ПДД. Выезд за пределы местности, в которой
                        данным Договором разрешено Арендатору использования транспортного
                        средства, строго запрещено и наказывается взысканием штрафа.
                    </p>
                    <p>
                        Любое использование транспортного средства может производиться
                        только по дорогам с твердым покрытием и в соответствии с ПДД.
                    </p>
                    <p>
                        Не допускается передача управления автомобилем третьим лицам, под
                        своим аккаунтом (учетной записи).
                    </p>
                </>
            );
        },
    },
    {
        heading: 'Права и обязанности сторон',
        body: () => {
            return (
                <>
                    <p>
                        Использование транспортного средства разрешено только в пределах
                        выделенной зоны (зона использования) и в соответствии с тарифным
                        планом избранное Арендатором.
                    </p>
                    <p>
                        Начало и завершение краткосрочная аренда автомобиля (проката)
                        производится в соответствии и только в местности разрешенной
                        настоящим Договором и ПДД. Выезд за пределы местности, в которой
                        данным Договором разрешено Арендатору использования транспортного
                        средства, строго запрещено и наказывается взысканием штрафа.
                    </p>
                    <p>
                        Любое использование транспортного средства может производиться
                        только по дорогам с твердым покрытием и в соответствии с ПДД.
                    </p>
                    <p>
                        Не допускается передача управления автомобилем третьим лицам, под
                        своим аккаунтом (учетной записи).
                    </p>
                </>
            );
        },
    },
    {
        heading: 'Персональные данные',
        body: () => {
            return (
                <>
                    <p>
                        Использование транспортного средства разрешено только в пределах
                        выделенной зоны (зона использования) и в соответствии с тарифным
                        планом избранное Арендатором.
                    </p>
                    <p>
                        Начало и завершение краткосрочная аренда автомобиля (проката)
                        производится в соответствии и только в местности разрешенной
                        настоящим Договором и ПДД. Выезд за пределы местности, в которой
                        данным Договором разрешено Арендатору использования транспортного
                        средства, строго запрещено и наказывается взысканием штрафа.
                    </p>
                    <p>
                        Любое использование транспортного средства может производиться
                        только по дорогам с твердым покрытием и в соответствии с ПДД.
                    </p>
                    <p>
                        Не допускается передача управления автомобилем третьим лицам, под
                        своим аккаунтом (учетной записи).
                    </p>
                </>
            );
        },
    },
    {
        heading: 'Использование автомобиля',
        body: () => {
            return (
                <>
                    <p>
                        Использование транспортного средства разрешено только в пределах
                        выделенной зоны (зона использования) и в соответствии с тарифным
                        планом избранное Арендатором.
                    </p>
                    <p>
                        Начало и завершение краткосрочная аренда автомобиля (проката)
                        производится в соответствии и только в местности разрешенной
                        настоящим Договором и ПДД. Выезд за пределы местности, в которой
                        данным Договором разрешено Арендатору использования транспортного
                        средства, строго запрещено и наказывается взысканием штрафа.
                    </p>
                    <p>
                        Любое использование транспортного средства может производиться
                        только по дорогам с твердым покрытием и в соответствии с ПДД.
                    </p>
                    <p>
                        Не допускается передача управления автомобилем третьим лицам, под
                        своим аккаунтом (учетной записи).
                    </p>
                </>
            );
        },
    },
    {
        heading: 'Ответственность сторон',
        body: () => {
            return (
                <>
                    <p>
                        Использование транспортного средства разрешено только в пределах
                        выделенной зоны (зона использования) и в соответствии с тарифным
                        планом избранное Арендатором.
                    </p>
                    <p>
                        Начало и завершение краткосрочная аренда автомобиля (проката)
                        производится в соответствии и только в местности разрешенной
                        настоящим Договором и ПДД. Выезд за пределы местности, в которой
                        данным Договором разрешено Арендатору использования транспортного
                        средства, строго запрещено и наказывается взысканием штрафа.
                    </p>
                    <p>
                        Любое использование транспортного средства может производиться
                        только по дорогам с твердым покрытием и в соответствии с ПДД.
                    </p>
                    <p>
                        Не допускается передача управления автомобилем третьим лицам, под
                        своим аккаунтом (учетной записи).
                    </p>
                </>
            );
        },
    },
];
import {
  // useHistory,
  useLocation,
} from 'react-router';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import { BackLink, Button, TextInput } from 'components';

function ProfileEditPhoneForm({ onSubmit }) {
  // const history = useHistory();
  const { state } = useLocation();

  const validationSchema = Yup.object().shape({
    mobilePhone: Yup.string().required('Поле обязательно к заполнению'),
  });

  const initialValues = {
    mobilePhone: '',
  };

  const submitHandler = (values) => {
    onSubmit(values);
  };

  return (
    <>
      <div className='page__header'>
        <BackLink />
        <h1>Изменить номер</h1>
      </div>
      <div className='text-center color-light-gray text-tiny'>
        Текущий номер: {state}
      </div>

      <div className='profile-edit__form'>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submitHandler}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <Field
                name='mobilePhone'
                label='Введите новый номер'
                placeholder={' '}
                isValid={!errors.mobilePhone && touched.mobilePhone}
                isInvalid={errors.mobilePhone && touched.mobilePhone}
                error={errors.mobilePhone}
                component={TextInput}
              />

              <div className='auth__footer'>
                <Button disabled={!(isValid && dirty)} type='submit'>
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default ProfileEditPhoneForm;

import { toast } from 'react-toastify';

import { API_BASE_URL } from 'config';

const axios = require('axios');
let AUTH_TOKEN = localStorage.getItem('authToken');

export const api = axios.create({
  baseURL: API_BASE_URL,
  // headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` },
  // timeout: 100,
});

export const setApiToken = (token) => {
  AUTH_TOKEN = token;
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

api.interceptors.request.use(function (config) {
  if (AUTH_TOKEN) {
    config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
  }

  return config;
});

api.interceptors.response.use((response) => {
  return response.data;
}, error => {
  if (error?.response?.status === 401) {
    toast(`Ваша сессия истекла. Авторизуйтесь заново`);
    logout();
  }

  return Promise.reject(error);
});

export const logout = () => {
  window.location.href = '/auth';
  api.defaults.headers.common['Authorization'] = null;
  localStorage.removeItem('authToken');
}

export default api;
import { Field, Form, Formik } from 'formik';

import { Logo, BackLink, Button, PasswordInput } from 'components';

import { SET_PASSWORD_SCHEMA } from './SetPassword-data';

function SetPassword({ initialValues, onSubmit }) {
  const validationSchema = SET_PASSWORD_SCHEMA;

  const submitHandler = ({ password }) => {
    onSubmit({ password: password });
  };

  return (
    <section className='container-wrap container-wrap--justify-center'>
      <section className='container'>
        <BackLink />

        <Logo />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submitHandler}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name='password'
                placeholder='Новый пароль'
                isValid={!errors.password && touched.password}
                isInvalid={errors.password && touched.password}
                hasRecoveryLink={false}
                error={errors.password}
                component={PasswordInput}
              />
              <Field
                name='confirm'
                placeholder='Подтверждение пароля'
                isValid={!errors.confirm && touched.confirm}
                isInvalid={errors.confirm && touched.confirm}
                hasRecoveryLink={false}
                error={errors.confirm}
                component={PasswordInput}
              />

              <div className='auth__footer'>
                <Button type='submit'>
                  Продолжить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </section>
  );
}

export default SetPassword;

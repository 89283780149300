import './ProfileImg.scss';

function ProfileImg({ img, isCentered }) {
  return (
    <div className={`profile-img ${isCentered ? 'profile-img--center' : ''}`}>
      <img
        src={
          img
            ? `data:image/jpeg;base64,${img}`
            : '/assets/images/avatar-default.png'
        }
        alt='Фотография пользователя'
      />
    </div>
  );
}

export default ProfileImg;

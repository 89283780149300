import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import Login from './login/Login';
import Register from './register/Register';
import Recovery from './recovery/Recovery';
import Rules from './rules/Rules';

function Auth({ onLogin, onRegister, onRecovery }) {
  const { path } = useRouteMatch();

  const loginHandler = (token) => {
    onLogin(token);
  };

  const registerHandler = () => {
    onRegister();
  };

  const recoveryHandler = () => {
    onRecovery();
  };

  return (
    <Switch>
      <Route path={`${path}/login`}>
        <Login onLogin={loginHandler} />
      </Route>

      <Route path={`${path}/register`}>
        <Register onRegister={registerHandler} />
      </Route>

      <Route path={`${path}/recovery`}>
        <Recovery onRecovery={recoveryHandler} />
      </Route>

      <Route path={`${path}/rules`}>
        <Rules />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}/login`} />
      </Route>
    </Switch>
  );
}

export default Auth;

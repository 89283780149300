import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';

import ProfileEditPhoneForm from './profile-edit-phone-form/ProfileEditPhoneForm';
import ProfileEditPhoneSms from './profile-edit-phone-sms/ProfileEditPhoneSms';

function ProfileEditPhone({ onSubmit }) {
  const history = useHistory();
  const { state } = useLocation();
  const { path } = useRouteMatch();

  const [phone, setPhone] = useState(state);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ProfileEditPhoneForm
          onSubmit={(values) => {
            setPhone(values.mobilePhone);
            history.push(`${path}/sms`);
          }}
        />
      </Route>

      <Route path={`${path}/sms`}>
        <ProfileEditPhoneSms
          phone={phone}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default ProfileEditPhone;

function Button({ className, color, type, children, onClick, disabled }) {
  const colorClassName = color ? `btn--${color}` : 'btn--blue';
  const classNames = `btn ${className ? className : ''} ${colorClassName}`;

  const clickHandler = (event) => {
    if (!onClick) {
      return;
    }
    onClick(event);
  };

  return (
    <button
      disabled={disabled}
      className={classNames}
      type={type ? type : 'button'}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
}

export default Button;

import React, { useState } from 'react';
import { Button } from 'components';

import './Library.scss';

function Library() {
  window.addEventListener('message', (event) => {
    const data = event.data;
    if (data?.source === 'flutterMobileWrapper') {
      setNumber(data.message);
    }
  });

  const [number, setNumber] = useState(null);

  const clickHandler = () => {
    window.postMessage('RANDOM_NUMBER_REQUEST');
  };

  const messageSnippet = `
    window.postMessage({
        payload: {
            type: 'RANDOM_NUMBER_REQUEST',
        },
        source: 'reactWebApp',
    });
  `;

  const codeSnippet = `
    sendResponseFromFlutter('Тут твое число или что-нибудь еще ');`;

  return (
    <div>
      <br />
      <h1>Тестирование</h1>
      <br />
      <p>
        Для того чтобы понять работает метод или нет, нужно запустить следующий
        сниппет со стороны Flutter-а:
      </p>
      <br />
      <code>{codeSnippet}</code>
      <br />
      <br />
      <p>
        Веб-приложение будет отправлять сообщение в объект{' '}
        <strong>window</strong> к примеру такого формата:
      </p>
      <br />
      <code>{messageSnippet}</code>
      <br />
      <br />
      <p className='color-light-gray'>
        После(если сработает) договоримся по остальным методам, которые нужны с
        нативки
      </p>
      <br />
      <br />

      {number ? (
        <h2 className='color-green'>
          C оболочки была получена цифра: <br />
          {number}
        </h2>
      ) : (
        ''
      )}
      <Button onClick={clickHandler}>Запросить рандомную цифру</Button>
    </div>
  );
}

export default Library;

import { Pin } from 'components';

function RegisterPinRepeat({ currentPin, onPinEnter, onPinDismiss }) {
  const pinEnterHandler = (pin) => {
    onPinEnter(pin);
  };

  const validatePin = (pin) => {
    return pin === currentPin;
  };

  return (
    <section className='container-wrap'>
      <section className='container'>
        <Pin
          onEnter={pinEnterHandler}
          validatePin={validatePin}
          isBiometryShown={false}
          errorMessage='Неправильный повтор кода, повторите еще раз'
          isDismissable={true}
          onDismiss={onPinDismiss}
          onReset={onPinDismiss}
          title='Повторите код'
        />
      </section>
    </section>
  );
}

export default RegisterPinRepeat;

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';
import { toast } from 'react-toastify';

import ProfileEditMain from './profile-edit-main/ProfileEditMain';
import ProfileEditEmail from './profile-edit-email/ProfileEditEmail';
import ProfileEditPhone from './profile-edit-phone/ProfileEditPhone';
import ProfileEditPassword from './profile-edit-password/ProfileEditPassword';
import ProfileEditPin from './profile-edit-pin/ProfileEditPin';

import './ProfileEdit.scss';

function ProfileEdit() {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ProfileEditMain />
      </Route>

      <Route path={`${path}/email`}>
        <ProfileEditEmail />
      </Route>

      <Route path={`${path}/phone`}>
        <ProfileEditPhone
          onSubmit={(values) => {
            console.log(values);
            history.push(path);
          }}
        />
      </Route>

      <Route path={`${path}/password`}>
        <ProfileEditPassword
          onSubmit={(values) => {
            console.log(values);
            history.push(path);
            toast(`Ваш пароль был успешно изменен`);
          }}
        />
      </Route>

      <Route path={`${path}/pin`}>
        <ProfileEditPin
          onSubmit={(pin) => {
            localStorage.setItem('pin', pin);
            history.push(path);
            toast(`Ваш код был успешно изменен`);
          }}
        />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default ProfileEdit;

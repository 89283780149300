import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router';

import SessionForm from './session-form/SessionForm';
import SessionType from './session-type/SessionType';

function Session() {
  const history = useHistory();
  const { path } = useRouteMatch();

  const selectHandler = (type) => {
    console.log(type);
    history.push(`${path}/primary`);
  };

  return (
    <Switch>
      <Route path={`${path}/type`}>
        <SessionType onSelect={selectHandler} />
      </Route>

      <Route path={`${path}/primary`} component={SessionForm} />

      <Route path='*'>
        <Redirect to={`${path}/type`} />
      </Route>
    </Switch>
  );
}

export default Session;

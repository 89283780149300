import * as Yup from 'yup';

import { REGISTER_INITIAL_VALUES } from '../Register-data';

export const FORM_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Поле обязательно к заполнению'),
  surname: Yup.string().required('Поле обязательно к заполнению'),
  email: Yup.string().required('Поле обязательно к заполнению').email('Email введен некорректно'),
  phone: Yup.string().required('Поле обязательно к заполнению')
});

export const FORM_INITIAL_VALUES = {
  name: REGISTER_INITIAL_VALUES.name,
  surname: REGISTER_INITIAL_VALUES.surname,
  email: REGISTER_INITIAL_VALUES.email,
  phone: REGISTER_INITIAL_VALUES.phone,
}
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';

import { Button, TextInput } from 'components';

import { BackLink } from 'components';

function ProfileEditEmail() {
  const history = useHistory();
  const { state } = useLocation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Поле обязательно к заполнению')
      .email('Email введен некорректно'),
  });

  const initialValues = {
    email: '',
  };

  const submitHandler = (values) => {
    console.log(values);

    toast(`В скором времени ваша почта будет изменена`);

    history.goBack();
  };

  return (
    <section className='profile-edit'>
      <div className='page__header'>
        <BackLink />
        <h1>Изменить email</h1>
      </div>
      <div className='text-center color-light-gray text-tiny'>
        Текущий адрес: {state}
      </div>

      <div className='profile-edit__form'>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submitHandler}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <Field
                name='email'
                label='Введите новый email'
                placeholder={' '}
                isValid={!errors.email && touched.email}
                isInvalid={errors.email && touched.email}
                error={errors.email}
                component={TextInput}
              />

              <div className='auth__footer'>
                <Button disabled={!(isValid && dirty)} type='submit'>
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default ProfileEditEmail;

export const SESSION_TYPES = [
    {
        label: 'Первичная установка',
        value: '1'
    },
    {
        label: 'Демонтаж устройства',
        value: '2'
    },
    {
        label: 'Замена SIM карты',
        value: '3'
    },
    {
        label: 'Замена устройства',
        value: '3'
    },
    {
        label: 'Диагностика',
        value: '4'
    },
    {
        label: 'Перенос устройства',
        value: '5'
    },
    {
        label: 'Демонстрация',
        value: '6'
    },
]
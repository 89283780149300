import { useState } from 'react';

import { Button } from 'components';

import './Select.scss';

function Select({ options, onSubmit }) {
  const [selected, setSelected] = useState(null);

  const isSelected = (option) => {
    if (!selected) {
      return false;
    }

    return option === selected;
  };

  return (
    <div className='select'>
      {options.map((option, key) => {
        return (
          <button
            key={key}
            className={`select__option ${
              isSelected(option) ? 'select__option--selected' : ''
            }`}
            onClick={() => {
              setSelected(option);
            }}
          >
            <span>{option.label}</span>
            {isSelected(option) ? (
              <img src='/assets/icons/selected.svg' alt='Выбрано' />
            ) : (
              ''
            )}
          </button>
        );
      })}
      <Button
        className='select__submit'
        onClick={() => {
          onSubmit(selected);
        }}
      >
        Применить
      </Button>
    </div>
  );
}

export default Select;

import { useHistory } from 'react-router';

import { Pin } from '../../../../components';

function LoginPin({ onLogin }) {
  const history = useHistory();
  
  const currentPin = localStorage.getItem('pin');
  const currentToken = localStorage.getItem('authToken');

  const validatePin = (pin) => {
    return pin === currentPin;
  };

  const pinResetHandler = () => {
    history.push('/auth/login/form');
  };

  return (
    <section className='container-wrap'>
      <section className='container'>
        <Pin
          onEnter={() => {
            onLogin(currentToken);
          }}
          onReset={pinResetHandler}
          validatePin={validatePin}
          title='Введите код для входа'
        />
      </section>
    </section>
  );
}

export default LoginPin;

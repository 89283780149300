import { useEffect, useState } from 'react';

import { BackLink, Button } from 'components';

import './Sms.scss';

const COUNTDOWN = 60;

function Sms({ onEnter, validateCode, requestCode }) {
  const [error, setError] = useState('');
  const [code, setCode] = useState('');

  const [counter, setCounter] = useState(COUNTDOWN);

  const renderDots = () => {
    const dots = [];

    for (let i = 1; i <= 4; i++) {
      const errorClassName = error ? 'sms__dot--errored' : '';
      const activeClassName = code.length >= i ? 'sms__dot--active' : '';
      const classNames = `${activeClassName} ${errorClassName}`;

      dots.push(
        <div key={`dot-${i}`} className={`sms__dot ${classNames}`}></div>
      );
    }
    return dots;
  };

  const requestSms = () => {
    requestCode()
      .then(() => {
        setCounter(COUNTDOWN);
      })
      .catch(() => {
        setCounter(0);
      });
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    requestSms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code.length !== 4) {
      return;
    }

    if (!validateCode) {
      onEnter(code);
      setError('');
    } else {
      validateCode(code)
        .then((success) => {
          onEnter(code);
          setError('');
        })
        .catch((error) => {
          setError('Неверный код, повторите еще раз');
        });
    }

    setCode('');
  }, [code, onEnter, validateCode, setError, error]);

  return (
    <section className='sms'>
      <div className='sms__header'>
        <BackLink />
        <h1 className='text-center'>Введите код из SMS</h1>
      </div>

      <div className='text-center'>
        <small className='color-light-dark'>
          На ваш телефон номер был отправлен SMS-код
        </small>

        <div className='sms__dots'>{renderDots()}</div>

        {error ? <small className='color-red'>{error}</small> : ''}

        <div className='sms__footer'>
          {counter > 0 ? (
            <div className='sms__countdown'>
              <small className='color-light-dark'>
                Получить код повторно можно через
              </small>
              &nbsp;
              <small className='color-green'>{counter} сек</small>
            </div>
          ) : (
            <div className='sms__retryable'>
              <small className='color-light-dark'>Не получили SMS?</small>
              <button
                onClick={() => {
                  requestSms();
                }}
                className='sms__retry-link'
              >
                <small className='color-green'>Повторно отправить код</small>
              </button>
            </div>
          )}
        </div>

        <Button type='button'>Продолжить</Button>
      </div>
    </section>
  );
}

export default Sms;

import { useEffect, useState } from 'react';

import { LogoSmall } from 'components';

import './Pin.scss';

const isBiometryCorrect = () => {
  return true;
};

function Pin({
  title,
  onEnter,
  onReset,
  onDismiss,
  validatePin,
  isBiometryShown = true,
  isDismissable = false,
  errorMessage = 'Код введен неверно, повторите еще раз',
}) {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [tries, setTries] = useState(3);

  useEffect(() => {
    if (pin.length !== 4) {
      return;
    }

    if (!validatePin) {
      onEnter(pin);
      setError('');
    } else if (validatePin(pin)) {
      onEnter(pin);
      setError('');
    } else {
      if (tries > 1) {
        setError(errorMessage);
        setTries(tries - 1);
      } else {
        onReset();
        setError('');
      }
    }

    setPin('');
  }, [
    pin,
    onEnter,
    validatePin,
    tries,
    setError,
    setTries,
    error,
    errorMessage,
    onReset,
  ]);

  const biometryHandler = () => {
    if (isBiometryCorrect()) {
      onEnter();
    }
  };

  const dismissHandler = () => {
    if (!onDismiss) {
      return;
    }

    onDismiss();
  };

  const numberPressHandler = (number) => {
    setPin(`${pin}${number}`);
  };

  const clearHandler = () => {
    setPin(pin.substring(0, pin.length - 1));
  };

  const renderDots = () => {
    const dots = [];

    for (let i = 1; i <= 4; i++) {
      const errorClassName = error ? 'pin__dot--errored' : '';
      const activeClassName = pin.length >= i ? 'pin__dot--active' : '';
      const classNames = `${activeClassName} ${errorClassName}`;

      dots.push(
        <div key={`dot-${i}`} className={`pin__dot ${classNames}`}></div>
      );
    }
    return dots;
  };

  const renderButtons = () => {
    const buttons = [];

    for (let i = 1; i <= 9; i++) {
      buttons.push(
        <button
          key={`button-${i}`}
          className='pin__button'
          onClick={() => {
            numberPressHandler(i);
          }}
        >
          <div className='pin__number'>{i}</div>
        </button>
      );
    }

    buttons.push(
      isBiometryShown ? (
        <button
          key='button-biometry'
          className='pin__button'
          onClick={biometryHandler}
        >
          <img
            alt='Вход по биометрии'
            src='/assets/icons/face-id.svg'
            className='pin__icon'
          />
        </button>
      ) : (
        <div key='button-biometry'></div>
      )
    );

    buttons.push(
      <button
        key='button-0'
        className='pin__button'
        onClick={() => {
          numberPressHandler(0);
        }}
      >
        <div className='pin__number'>0</div>
      </button>
    );

    buttons.push(
      <button key='button-clear' className='pin__button' onClick={clearHandler}>
        <img
          alt='Удалить'
          src='/assets/icons/backspace.svg'
          className='pin__icon'
        />
      </button>
    );

    return buttons;
  };

  return (
    <section className='pin'>
      <LogoSmall />

      <h1 className='pin__title'>{title}</h1>

      <div className='pin__content'>
        <div className='pin__dots'>{renderDots()}</div>
        <div className='pin__buttons'>{renderButtons()}</div>
        <div className='pin__footer'>
          {error ? (
            <small className='pin__message color-red'>{error}</small>
          ) : (
            ''
          )}

          {validatePin ? (
            <small className='pin__message color-light-dark'>
              Попыток осталось: {tries}
            </small>
          ) : (
            ''
          )}

          {isDismissable ? (
            <button
              onClick={dismissHandler}
              className='pin__message pin__dismiss link-blue'
            >
              Придумать новый быстрый код
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
}

export default Pin;

import { useState } from 'react';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';

import RegisterForm from './register-form/RegisterForm';
import RegisterPassword from './register-password/RegisterPassword';
import RegisterPin from './register-pin/RegisterPin';
import RegisterPinRepeat from './register-pin-repeat/RegisterPinRepeat';

import { REGISTER_INITIAL_VALUES } from './Register-data';
import { register } from './Register-api';

function Register({ onRegister }) {
  const history = useHistory();

  const { path } = useRouteMatch();

  const [form, setForm] = useState(REGISTER_INITIAL_VALUES);
  const [pin, setPin] = useState('');

  const formSubmitHandler = (values) => {
    setForm({ ...form, ...values });
    history.push(`${path}/password`);
  };

  const passwordSubmitHandler = (values) => {
    const updatedForm = { ...form, ...values };
    setForm(updatedForm);
    registerApi(updatedForm);
  };

  const pinEnterHandler = (pin) => {
    setPin(pin);
    history.push(`${path}/pin-repeat`);
  };
  
  const pinRepeatHandler = (pin) => {
    localStorage.setItem('pin', pin);
    onRegister();
  };

  const pinRepeatDismissHandler = () => {
    history.push(`${path}/pin`);
  };

  const registerApi = async (form) => {
    try {
      await register(form);
    } catch (error) {
    } finally {
      history.push(`${path}/pin`);
    }
  };

  return (
    <Switch>
      <Route path={`${path}/form`}>
        <RegisterForm onFormSubmit={formSubmitHandler} />
      </Route>

      <Route path={`${path}/password`}>
        <RegisterPassword onPasswordSubmit={passwordSubmitHandler} />
      </Route>

      <Route path={`${path}/pin`}>
        <RegisterPin onPinEnter={pinEnterHandler} />
      </Route>

      <Route path={`${path}/pin-repeat`}>
        <RegisterPinRepeat
          onPinEnter={pinRepeatHandler}
          currentPin={pin}
          onPinDismiss={pinRepeatDismissHandler}
        />
      </Route>

      <Route path='*'>
        <Redirect to={`${path}/form`} />
      </Route>
    </Switch>
  );
}

export default Register;

import { SetPassword } from 'components';
import { useQuery } from 'core';

import { PASSWORD_INITIAL_VALUES } from './RecoveryPassword-data';

function RecoveryPassword({ onSubmit }) {
  const initialValues = PASSWORD_INITIAL_VALUES;

  const query = useQuery();
  const code = query.get('code');
  const phone = query.get('phone');
  const email = query.get('email');

  const passwordSubmitHandler = (values) => {
    const additionalValues = email ? { email } : { phone };

    onSubmit({ ...values, ...additionalValues, code });
  };

  return (
    <SetPassword
      initialValues={initialValues}
      onSubmit={passwordSubmitHandler}
    />
  );
}

export default RecoveryPassword;
